<template>
    <div class="w-100">
        <div class="d-flex flex-wrap mt-4">
            <table v-if="itemsCurrent.length" class="table" :class="tableDark">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                        <th scope="col">Llave</th>
                        <th scope="col">Tipo</th>
                        <th v-if="enabled" scope="col">
                            <div class="d-flex justify-content-center">
                                <span>🧤</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in itemsCurrent" :key="index">
                        <th scope="row">{{ index }}</th>
                        <td class="col-3">
                            <span>
                                {{ item.name }}
                            </span>
                        </td>
                        <td class="col-3">
                            <span>
                                {{ item.key }}
                            </span>
                        </td>
                        <td class="col-3">
                            <span>
                                {{ item.type }}
                            </span>
                        </td>
                        <td v-if="enabled" class="col-2">
                            <div class="d-flex justify-content-center">
                                <button
                                    class="btn len-3 d-flex align-items-center justify-content-center"
                                    :disabled="index === 0"
                                    @click="moveOrder(index, index - 1)"
                                >
                                    ⬆
                                </button>

                                <button
                                    class="btn len-3 d-flex align-items-center justify-content-center"
                                    :disabled="index >= itemsCurrent.length - 1"
                                    @click="moveOrder(index, index + 1)"
                                >
                                    ⬇
                                </button>
                                <button
                                    class="btn btn-danger len-3 d-flex align-items-center justify-content-center"
                                    @click="deleteItem(index)"
                                >
                                    🗑️
                                </button>
                                <button
                                    class="btn len-3 d-flex align-items-center justify-content-center"
                                    @click="editItem(index)"
                                >
                                    🖊
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-else><span>No hay filtros</span></div>
        </div>

        <div v-if="enabled" class="d-flex flex-wrap">
            <div class="col-8 offset-2 d-flex justify-content-center">
                <slot name="action"></slot>
            </div>
            <div class="col-2">
                <button
                    v-if="enabled"
                    class="ml-4 mt-2 border border-secondary btn rounded-circle d-flex justify-content-center align-items-center p-2"
                    @click="openNewFilter"
                >
                    <span>➕</span>
                </button>
            </div>
        </div>

        <div>
            <vs-dialog
                v-model="isOpenModalSelectItem"
                scroll
                overflow-hidden
                auto-width
            >
                <template #header> </template>
                <div class="con-content border rounded border-info py-2 px-4">
                    <span class="h3">
                        Selecciona el tipo de filtro que quieres. Luego da click
                        en el check.
                    </span>
                    <div class="d-flex flex-wrap">
                        <div class="col-12 mt-4">
                            <span> Tipo de filtro </span>
                            <vs-select
                                v-model="itemTemp.key"
                                border
                                shadow
                                :disabled="isEditingItem"
                                :state="stateInputDark"
                                autocomplete="off"
                                class="w-100 mw-100 border-custom"
                                name="key"
                            >
                                <vs-option
                                    v-for="(item, index) in $data.$filtersTypes"
                                    :key="index"
                                    :label="item.value"
                                    :value="item.key"
                                >
                                    {{ item.value }}
                                </vs-option>
                            </vs-select>
                        </div>
                        <div class="col-6 mt-4">
                            <span> Llave de búsqueda </span>
                            <vs-input
                                id="key_"
                                v-model="itemTemp.key"
                                :disabled="isEditingItem"
                                autocomplete="off"
                                border
                                shadow
                                state="success"
                                type="text"
                                name="key"
                            />
                        </div>
                        <div class="col-6 mt-4">
                            <span> Nombre </span>
                            <vs-input
                                id="name_"
                                v-model="itemTemp.name"
                                border
                                shadow
                                autocomplete="off"
                                state="success"
                                type="text"
                                name="name"
                            />
                        </div>
                    </div>
                    <div
                        v-if="itemTemp.type === 'range'"
                        class="d-flex flex-wrap"
                    >
                        <div class="col-6 mt-4">
                            <span> Inicio </span>
                            <vs-input
                                id="start_"
                                v-model="start"
                                border
                                shadow
                                autocomplete="off"
                                state="success"
                                step="10000"
                                type="text"
                                name="start"
                                @input="formatStart"
                            />
                        </div>
                        <div class="col-6 mt-4">
                            <span> Fin </span>
                            <vs-input
                                id="end_"
                                v-model="end"
                                border
                                shadow
                                autocomplete="off"
                                state="success"
                                step="10000"
                                type="text"
                                name="end"
                                @input="formatEnd"
                            />
                        </div>
                        <div class="col-6 mt-4">
                            <span> Salto </span>
                            <vs-input
                                id="step_"
                                v-model="step"
                                border
                                shadow
                                autocomplete="off"
                                state="success"
                                step="10000"
                                type="text"
                                name="step"
                                @input="formatStep"
                            />
                        </div>
                    </div>
                    <div
                        v-if="
                            itemTemp.type === 'checkbox' ||
                            itemTemp.type === 'select'
                        "
                        class="my-4"
                    >
                        <p>
                            El <strong>nombre</strong> hace referencia la cadena
                            de texto que se muestra al cliente en el
                            {{ $site }} en el checkbox. El
                            <strong>valor</strong> debe estar escrito en idioma
                            ingles con formato "snake_case", cada producto que
                            se quiera encontrar con estas llaves debe tener la
                            etiqueta en el conjunto de etiquetas.
                        </p>
                        <feature-manage v-model="itemTemp.items" enabled />
                    </div>

                    <div v-if="showDuplicate" class="mx-3 mt-2">
                        <span class="h3 text-danger mt-4">
                            Ya tienes un filtro con la llave {{ itemTemp.key }}
                        </span>
                    </div>
                    <div class="d-flex justify-content-center mt-4">
                        <vs-button
                            :disabled="isInvalidCurrentItem"
                            class="mt-3 mb-5 col-9 mx-auto"
                            border
                            gradient
                            @click="pushOrUpdateItem"
                        >
                            <span class="h4">
                                {{
                                    isEditingItem
                                        ? "Actualizar filtro ✔️"
                                        : "Agregar filtro ✔️"
                                }}</span
                            >
                        </vs-button>
                    </div>
                </div>
            </vs-dialog>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { filtersTypes } from "@/common/fields/filters-types";

import FeatureManage from "@/components/products/FeatureManage.vue";

export default {
    name: "FiltersManage",
    components: { FeatureManage },
    model: {
        prop: "itemsCurrent",
        event: "change"
    },
    props: {
        itemsCurrent: {
            type: Array,
            default: () => [
                {
                    name: "alt",
                    type: "input",
                    key: "priceOffer",
                    items: [{ name: "Innovalombia", value: "innovalombia" }],
                    range: {
                        start: 0,
                        end: 10000,
                        step: 5000
                    }
                }
            ],
            required: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        isOpenModalSelectItem: false,
        $filtersTypes: filtersTypes,
        selectedFilters: [],
        itemTemp: {
            name: "",
            type: "",
            key: "",
            items: [],
            range: {
                start: 0,
                end: 20000,
                step: 10000
            }
        },
        start: "$0",
        end: "$20.000",
        step: "$10.000",
        edit: false,
        showDuplicate: false,
        isEditingItem: false
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark", "tableDark"]),
        isInvalidCurrentItem() {
            return (
                this.itemTemp.name === "" ||
                this.itemTemp.type === "" ||
                this.itemTemp.key === "" ||
                this.showDuplicate
            );
        },
        itemTempType() {
            return this.itemTemp.type;
        },
        itemTempKey() {
            return this.itemTemp.key;
        }
    },
    watch: {
        itemTempKey(value) {
            if (this.isEditingItem) return;
            const filter = filtersTypes.find((item) => item.key === value);
            if (filter) {
                this.itemTemp.name = filter.name;
                this.itemTemp.type = filter.type;
                this.itemTemp.items = filter.items;
                this.itemTemp.range = filter.range;
            }
            this.showDuplicate = this.itemsCurrent.some(
                (item) => item.key === value
            );
        }
    },
    methods: {
        moveOrder(fromIndex, toIndex) {
            let arrAux = [...this.itemsCurrent];
            const aux = arrAux[fromIndex];
            arrAux.splice(fromIndex, 1);
            arrAux.splice(toIndex, 0, aux);
            this.$emit("change", arrAux);
        },
        editItem(fromIndex) {
            this.isEditingItem = true;
            let arrAux = [...this.itemsCurrent];
            this.itemTemp = arrAux[fromIndex];
            this.isOpenModalSelectItem = true;
            if ("step" in this.itemTemp) {
                this.end = this.$options.filters.currency(
                    Number(this.itemTemp.range.end)
                );
                this.step = this.$options.filters.currency(
                    Number(this.itemTemp.range.step)
                );
                this.start = this.$options.filters.currency(
                    Number(this.itemTemp.range.start)
                );
            }
        },
        deleteItem(fromIndex) {
            let itemsResult = [...this.itemsCurrent];
            itemsResult.splice(fromIndex, 1);
            this.$emit("change", itemsResult);
        },
        selectionFilter(id) {
            this.selectedFilters.push(id);
        },
        openNewFilter() {
            this.itemTemp = {
                name: "",
                type: "",
                key: "",
                items: [],
                range: {
                    start: 0,
                    end: 20000,
                    step: 10000
                }
            };
            this.isOpenModalSelectItem = true;
        },
        pushOrUpdateItem() {
            const itemsAux = [...this.itemsCurrent];
            if (this.isEditingItem) {
                const itemAux = itemsAux.findIndex(
                    (item) => item.key === this.itemTemp.key
                );
                itemsAux[itemAux] = this.itemTemp;
                this.isEditingItem = false;
            } else {
                itemsAux.push(this.itemTemp);
            }
            this.$emit("change", itemsAux);
            this.isOpenModalSelectItem = false;
        },
        formatStep() {
            this.itemTemp.range.step = parseInt(
                this.step.replace(/[^0-9]+/g, "")
            );
            this.step = this.$options.filters.currency(
                this.itemTemp.range.step
            );
        },
        formatEnd() {
            this.itemTemp.range.end = parseInt(
                this.end.replace(/[^0-9]+/g, "")
            );
            this.end = this.$options.filters.currency(this.itemTemp.range.end);
        },
        formatStart() {
            this.itemTemp.range.start = parseInt(
                this.start.replace(/[^0-9]+/g, "")
            );
            this.start = this.$options.filters.currency(
                this.itemTemp.range.start
            );
        }
    }
};
</script>
